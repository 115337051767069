import { useToast } from "vue-toastification";
import { VueCookies } from "vue-cookies";
import { COOKIE_SESSION_ID } from "../../../shared/types/common.types";

let toastr = useToast();

/**
 * Components, that have logout button.
 */
export function useLogout(store: any, cookies: VueCookies): void {
    store.setAuthenticated(false);
    store.setJwtString(null);
    store.setCurrentUser(null);
    cookies.remove(COOKIE_SESSION_ID);
    toastr.info("You are successfully logged out");
}
