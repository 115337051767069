<template>
  <div class="modal fade" id="modal-invite" tabindex="-1" aria-labelledby="modal-invite" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-fullscreen-xl-down">
      <div class="modal-content">

        <!-- Modal: Body -->
        <div class="modal-body py-0">
          <!-- Header -->
          <div class="profile modal-gx-n">
            <div class="profile-img text-primary rounded-top-xl">
              <div v-html="(require(`!html-loader!/public/assets/img/core/card-header.svg`)).default"></div>

              <div class="position-absolute top-0 start-0 p-5">
                <button type="button" class="btn-close btn-close-white btn-close-arrow opacity-100" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            </div>

            <div class="profile-body">
              <div class="avatar avatar-lg">
                <span class="avatar-text bg-primary" v-html="(require(`!html-loader!/public/assets/img/icons/user-plus.svg`)).default">
                </span>
              </div>

              <h4 class="fw-bold mb-1">Invite your friends</h4>
              <p style="font-size: 16px;">Send invitation links to your friends</p>
            </div>
          </div>
          <!-- Header -->

          <hr class="hr-bold modal-gx-n my-0">

          <!-- Form -->
          <div class="modal-py">
            <form class="row gy-6">
              <div class="col-12">
                <label for="invite-email" class="form-label text-muted">E-mail</label>
                <input type="email" class="form-control form-control-lg" id="invite-email" placeholder="name@example.com">
              </div>

              <div class="col-12">
                <label for="invite-message" class="form-label text-muted">Message</label>
                <textarea class="form-control form-control-lg" id="invite-message" rows="3" placeholder="Custom message"></textarea>
              </div>
            </form>
          </div>
          <!-- Form -->

          <hr class="hr-bold modal-gx-n my-0">

          <!-- Button -->
          <div class="modal-py">
            <a href="#" class="btn btn-lg btn-primary w-100 d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#invite-modal">
              Send

              <span class="icon ms-auto" v-html="(require(`!html-loader!/public/assets/img/icons/chevron-right.svg`)).default">
              </span>
            </a>
          </div>
          <!-- Button -->
        </div>
        <!-- Modal: Body -->

      </div>
    </div>
  </div>
</template>
