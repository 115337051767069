<template>
  <div class="dropdown">
    <a class="icon text-muted" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" v-html="(require(`!html-loader!/public/assets/img/icons/more-vertical.svg`)).default">
    </a>

    <ul class="dropdown-menu">
      <li>
        <a class="dropdown-item d-flex align-items-center" href="#">
          <span class="me-auto">Edit</span>
          <div class="icon" v-html="(require(`!html-loader!/public/assets/img/icons/edit-3.svg`)).default">
          </div>
        </a>
      </li>
      <li>
        <a class="dropdown-item d-flex align-items-center" href="#">
          <span class="me-auto">Reply</span>
          <div class="icon" v-html="(require(`!html-loader!/public/assets/img/icons/corner-up-left.svg`)).default">
          </div>
        </a>
      </li>
      <li>
        <hr class="dropdown-divider">
      </li>
      <li>
        <a class="dropdown-item d-flex align-items-center text-danger" href="#">
          <span class="me-auto">Delete</span>
          <div class="icon" v-html="(require(`!html-loader!/public/assets/img/icons/trash-2.svg`)).default">
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>
