// @ts-ignore
import * as fs from "fs";
import { UserEntity } from "../shared/entities/user.entity";

export default class StaticUtils {
    /**
     * Detect is current environment docker or host (dev machine)
     */
    static isDocker(): boolean {
        try {
            fs.statSync('/.dockerenv');
            return true;
        } catch {
            try {
                return fs.readFileSync('/proc/self/cgroup', 'utf8').includes('docker');
            } catch {
                return false;
            }
        }
    }

    static getUsersByLetters = (users: UserEntity[]) => {
        let mapUsersByFirstLetter: {[key: string]: UserEntity[]} = {};
        let result: {[key: string]: UserEntity[]} = {};
        // Make
        for (let user of users) {
            let firstLetterOfUser = user.name ? user.name.charAt(0).toUpperCase() : 'A';
            if (!mapUsersByFirstLetter.hasOwnProperty(firstLetterOfUser)) {
                mapUsersByFirstLetter[firstLetterOfUser] = [];
            }
            mapUsersByFirstLetter[firstLetterOfUser]!.push(user);
        }
        // Sort
        // @ts-ignore
        const alpha = Array.from(Array(26)).map((e, i) => i + 65);
        const alphabet = alpha.map((x) => String.fromCharCode(x));
        for (let letter of alphabet) {
            if (mapUsersByFirstLetter.hasOwnProperty(letter)) {
                result[letter] = mapUsersByFirstLetter[letter] as UserEntity[];
            }
        }
        return result;
    }
}
