import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "hide-scrollbar" }
const _hoisted_2 = { class: "container py-8" }
const _hoisted_3 = { class: "mb-8" }
const _hoisted_4 = { class: "fw-bold m-0" }
const _hoisted_5 = { class: "mb-6" }
const _hoisted_6 = { class: "mt-5 d-none" }
const _hoisted_7 = {
  href: "#",
  class: "btn btn-lg btn-primary w-100 d-flex align-items-center",
  "data-bs-toggle": "modal",
  "data-bs-target": "#modal-invite"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "card-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "d-flex flex-column h-100",
    key: $setup.lastRefresh
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode(" Title "),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", _hoisted_4, _toDisplayString($setup.props.title), 1 /* TEXT */)
        ]),
        _createCommentVNode(" Search "),
        _createElementVNode("div", _hoisted_5, [
          _createVNode($setup["Search"]),
          _createCommentVNode(" Invite button "),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("a", _hoisted_7, [
              _createTextVNode(" Find Friends "),
              _createElementVNode("span", {
                class: "icon ms-auto",
                innerHTML: (require(`!html-loader!/public/assets/img/icons/user-plus.svg`)).default
              }, null, 8 /* PROPS */, _hoisted_8)
            ])
          ])
        ]),
        _createCommentVNode(" List "),
        _createElementVNode("div", _hoisted_9, [
          _createCommentVNode(" RosterItem "),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.rosterItems, (rosterItem) => {
            return (_openBlock(), _createBlock($setup["RosterItem"], {
              item: rosterItem,
              avatar: rosterItem.contact.avatarId ? '/api/v1/media/show/' + rosterItem.contact.avatarId : '/img/no_image.png',
              status: '@' + rosterItem.contact.username,
              actions: [$setup.RosterItemAction.createMessage],
              onFriendNameClick: ($event: any) => ($setup.router.push({ name: 'room', params: {id: rosterItem.roomId }}))
            }, null, 8 /* PROPS */, ["item", "avatar", "status", "actions", "onFriendNameClick"]))
          }), 256 /* UNKEYED_FRAGMENT */)),
          _createCommentVNode(" /RosterItem ")
        ])
      ])
    ])
  ]))
}