import { defineStore } from 'pinia';
import { ref, Ref } from 'vue'
import { UserEntity } from "../../../shared/entities/user.entity";

type AuthStoreState = {
    authenticated: Ref<boolean>,
    currentUser: Ref<UserEntity>,
    isAdmin: Ref<boolean>,
    jwtString: Ref<string>,
    activeTab: Ref<string>,
    theme: Ref<string>
}

export const useAuthStore = defineStore({
    id: 'authStore',
    persist: {
        enabled: true
    },
    state: () =>
        ({
            authenticated: ref(false),
            currentUser: ref(null),
            isAdmin: ref(false),
            jwtString: ref(''),
            activeTab: ref('tab-content-chats'),
            theme: ref(null),
        } as AuthStoreState),
    getters: {
        isAuthenticated(): boolean {
            return this.authenticated;
        },
    },
    actions: {
       setAuthenticated(authenticated: boolean) {
            this.authenticated = authenticated;
       },
       setJwtString(jwtString: string) {
          this.jwtString = jwtString;
       },
       setActiveTab(tab: string) {
           this.activeTab = tab;
       },
       setCurrentUser(user: UserEntity) {
          this.currentUser = user;
          this.isAdmin = user ? user.roles.includes("ROLE_MESSENGER_ADMIN") : false;
       }
    },
})
