
/**
 * Components, that have links with data-toggle-chat="" earlier.
 */
export function useToggleChat(): void {
    let mainSection = document.querySelector('.main');
    if (mainSection) {
        mainSection.classList.toggle('is-visible');
    }
}
