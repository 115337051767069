/**
 * Init mitt
 */
import mitt, {Emitter} from "mitt";

export class EventBusService {
    private readonly emitter: Emitter<any>;

    public constructor() {
        this.emitter = mitt();
    }

    public getEmitter(): Emitter<any> {
        return this.emitter;
    }
}

// Ростер пользователя был обновлен -> надо перезагрузить его
export const EVENT_ROSTER_UPDATED = "rosterUpdated";
export type RosterUpdatedEvent = {
    ownerId: string; // id пользователя, ростер которого обновился
}

// Нажали на кнопку удаления из контакт-листа
export const EVENT_ROSTER_ITEM_REMOVE = "rosterItemRemove";
export type RosterItemRemoveEvent = {
    id: string; // RosterItemEntity.id
}

// Удаление пользователя из контакт-листа -> надо перезагрузить его
// export const EVENT_ROSTER_ITEM_REMOVED = "rosterItemRemoved";
