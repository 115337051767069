import { createApp } from "vue"
import configureDI from "./container"
import App from "./components/App.vue"
import {RouterService} from "./services/router.service"
import {StateManagerService} from "./services/state-manager.service"
import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";
import "../public/fontawesome/css/all.css"
import VueCookies from 'vue-cookies'
import {FrontendContainerConfig} from "../../shared/types/common.types";

window.onload = function () {
    /**
     * Base config.
     * Аналог .env в backend
     */
    // @todo сделать генерацию yaml/js файла из .env с помощью dotenv-webpack, чтобы не передавать пароль тут
    const config: FrontendContainerConfig = {
        serverPath: location.protocol + '//' + location.host,
    }

    /**
     * Init container
     */
    const container = configureDI(config);

    /**
     * Init services before root component mount
     */
    const app = createApp(App, {container});
    const options: PluginOptions = {
        // You can set your default options here
    };
    app.use(Toast, options);
    app.use(container.get(StateManagerService).getPinia());
    app.use(container.get(RouterService).getVueRouter());
    app.use(VueCookies);
    app.mount("#vue-app");
}


