import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card border-0 mt-5" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "row align-items-center gx-5" }
const _hoisted_4 = { class: "col-auto" }
const _hoisted_5 = { class: "avatar {{ props.online }}" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "avatar-text"
}
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "col-auto" }
const _hoisted_10 = { class: "form-check" }
const _hoisted_11 = ["value", "id"]
const _hoisted_12 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" Card "),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              ($setup.props.avatar)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "avatar-img",
                    src: $setup.props.avatar,
                    alt: ""
                  }, null, 8 /* PROPS */, _hoisted_6))
                : _createCommentVNode("v-if", true),
              ($setup.props.abbr)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($setup.props.abbr), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("h5", null, _toDisplayString($setup.props.name), 1 /* TEXT */),
            _createElementVNode("p", null, _toDisplayString($setup.props.status), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("input", {
                class: "form-check-input",
                type: "checkbox",
                value: $setup.props.id,
                id: 'id-member-' + $setup.props.id,
                onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.onChange && $setup.onChange(...args)))
              }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_11),
              (_openBlock(), _createElementBlock("label", {
                class: "form-check-label",
                key: 'id-member-' + $setup.props.id,
                for: 'id-member-' + $setup.props.id
              }, null, 8 /* PROPS */, _hoisted_12))
            ])
          ])
        ]),
        _createCommentVNode(" Если раскомментировать эту строчку - то перестанут кликаться checkbox-ы."),
        _createCommentVNode("<label class=\"stretched-label\" :for=\"'id-member-' + props.id\"></label>")
      ])
    ]),
    _createCommentVNode(" Card ")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}