import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { action: "#" }
const _hoisted_2 = { class: "input-group" }
const _hoisted_3 = { class: "input-group-text" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["placeholder", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "icon icon-lg",
          innerHTML: (require(`!html-loader!/public/assets/img/icons/search.svg`)).default
        }, null, 8 /* PROPS */, _hoisted_4)
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control form-control-lg ps-0",
        placeholder: $setup.placeholder,
        "aria-label": $setup.label,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.text) = $event)),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.onInputClick && $setup.onInputClick(...args)))
      }, null, 8 /* PROPS */, _hoisted_5), [
        [_vModelText, $setup.text]
      ])
    ])
  ]))
}