import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sidebar bg-light" }
const _hoisted_2 = {
  class: "tab-content h-100",
  role: "tablist"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createCommentVNode(" Rosters "),
      _createElementVNode("div", {
        class: _normalizeClass(["tab-pane fade h-100", { show: $setup.activeTab == 'tab-content-rosters', active: $setup.activeTab == 'tab-content-rosters'}]),
        id: "tab-content-rosters",
        role: "tabpanel"
      }, [
        _createVNode($setup["SidebarRosters"], { title: "Private rosters admin" })
      ], 2 /* CLASS */),
      _createCommentVNode(" Create "),
      _createElementVNode("div", {
        class: _normalizeClass(["tab-pane fade h-100", { show: $setup.activeTab == 'tab-content-create-chat', active: $setup.activeTab == 'tab-content-create-chat'}]),
        id: "tab-content-create-chat",
        role: "tabpanel"
      }, [
        _createVNode($setup["SidebarCreateChat"], { title: "Create group" })
      ], 2 /* CLASS */),
      _createCommentVNode(" Friends "),
      ($setup.currentUser)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["tab-pane fade h-100", { show: $setup.activeTab == 'tab-content-friends', active: $setup.activeTab == 'tab-content-friends'}]),
            id: "tab-content-friends",
            role: "tabpanel"
          }, [
            _createVNode($setup["SidebarFriends"], {
              id: $setup.currentUser.id,
              title: "My roster"
            }, null, 8 /* PROPS */, ["id"])
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" Chats "),
      _createElementVNode("div", {
        class: _normalizeClass(["tab-pane fade h-100", { show: $setup.activeTab == 'tab-content-chats', active: $setup.activeTab == 'tab-content-chats'}]),
        id: "tab-content-chats",
        role: "tabpanel"
      }, [
        _createVNode($setup["SidebarChats"], { title: "Chat groups" })
      ], 2 /* CLASS */),
      _createCommentVNode(" Notifications - Notices "),
      _createElementVNode("div", {
        class: _normalizeClass(["tab-pane fade h-100", { show: $setup.activeTab == 'tab-content-notifications', active: $setup.activeTab == 'tab-content-notifications'}]),
        id: "tab-content-notifications",
        role: "tabpanel"
      }, [
        _createVNode($setup["SidebarNotifications"], { title: "Notifications" })
      ], 2 /* CLASS */),
      _createCommentVNode(" Support "),
      _createElementVNode("div", {
        class: _normalizeClass(["tab-pane fade h-100", { show: $setup.activeTab == 'tab-content-support', active: $setup.activeTab == 'tab-content-support'}]),
        id: "tab-content-support",
        role: "tabpanel"
      }, [
        _createVNode($setup["SidebarSupport"], { title: "Support" })
      ], 2 /* CLASS */),
      _createCommentVNode(" Settings "),
      _createElementVNode("div", {
        class: _normalizeClass(["tab-pane fade h-100", { show: $setup.activeTab == 'tab-content-settings', active: $setup.activeTab == 'tab-content-settings'}]),
        id: "tab-content-settings",
        role: "tabpanel"
      }, [
        _createVNode($setup["SidebarSettings"], { title: "Settings" })
      ], 2 /* CLASS */)
    ])
  ]))
}