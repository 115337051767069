// Imports
import { createRouter, createWebHistory, Router as VueRouter } from "vue-router";
import { BootstrapService } from "./bootstrap.service";

// Components
import NotFound from "../components/NotFound.vue";
import Index from "../components/Index.vue"
import ChatGroup from "../components/ChatGroup.vue"
import ChatDirect from "../components/ChatDirect.vue";
import ChatEmpty from "../components/ChatEmpty.vue";
import SignIn from "../components/SignIn.vue"
import SignUp from "../components/SignUp.vue"
import PasswordReset from "../components/PasswordReset.vue"
import LockScreen from "../components/LockScreen.vue"
import UserRoster from "../components/UserRoster.vue";
import Room from "../components/Room.vue";

/**
 * Init router
 */
export class RouterService {

    private readonly vueRouter: VueRouter;

    public constructor(public readonly bootstrap: BootstrapService) {
        const routes = [
            {
                name: 'index',
                path: '/ui/',
                alias: ['/index.html'],
                component: Index
            },
            {
                name: 'ui',
                path: '/ui/*',
                redirect: { name: 'index' },
                children: [
                    {
                        name: 'user_roster',
                        path: '/ui/user/:id/roster',
                        component: UserRoster
                    },
                    {
                        name: 'room',
                        path: '/ui/room/:id',
                        component: Room
                    },
                ]
            },
            {
                name: 'chat_group',
                path: '/ui/chat-group.html',
                component: ChatGroup
            },
            {
                name: 'chat_direct',
                path: '/ui/chat-direct.html',
                component: ChatDirect
            },
            {
                name: 'chat_empty',
                path: '/ui/chat-empty.html',
                component: ChatEmpty
            },
            {
                name: 'sign_in',
                path: '/ui/signin.html',
                component: SignIn
            },
            {
                name: 'sign_up',
                path: '/ui/signup.html',
                component: SignUp
            },
            {
                name: 'password_reset',
                path: '/ui/password-reset.html',
                component: PasswordReset
            },
            {
                name: 'lock_screen',
                path: '/ui/lockscreen.html',
                component: LockScreen
            },
            // pathMatch is the name of the param, e.g., going to /not/found yields
            // { params: { pathMatch: ['not', 'found'] }}
            // this is thanks to the last *, meaning repeated params and it is necessary if you
            // plan on directly navigating to the not-found route using its name
            { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound },
            // if you omit the last `*`, the `/` character in params will be encoded when resolving or pushing
            { path: '/:pathMatch(.*)', name: 'bad-not-found', component: NotFound },
        ];
        let router = createRouter({
            history: createWebHistory(),
            // @ts-ignore
            routes, // short for `routes: routes`
        });

        // Autosize for input field in chat room
        // router.afterEach((to, from, failure) => {
        //     bootstrap.initAutosize();
        //     let meta: FrontendRouteMeta = to.meta;
        //     if (meta && meta.activeTab) {
        //         authStore.setActiveTab(meta.activeTab);
        //         console.log('Setting active tab in pinia: ' + meta.activeTab);
        //     }
        // })
        //router.beforeEach(async (to) => {
        //     // redirect to login page if not logged in and trying to access a restricted page
        //     const publicPages = ['/login'];
        //     const authRequired = !publicPages.includes(to.path);
        //     const auth = useAuthStore();
        //
        //     if (authRequired && !auth.user) {
        //         auth.returnUrl = to.fullPath;
        //         return '/login';
        //     }
        //});
        this.vueRouter = router;
    }

    public getVueRouter(): VueRouter {
        return this.vueRouter;
    }
}
