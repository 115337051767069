import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hide-scrollbar" }
const _hoisted_2 = { class: "container py-8" }
const _hoisted_3 = { class: "mb-8" }
const _hoisted_4 = { class: "fw-bold m-0" }
const _hoisted_5 = { class: "mb-6" }
const _hoisted_6 = { class: "card-list" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "card-body" }
const _hoisted_9 = { class: "row gx-5" }
const _hoisted_10 = { class: "col-auto" }
const _hoisted_11 = { class: "avatar avatar-online" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { class: "d-flex align-items-center mb-3" }
const _hoisted_15 = { class: "me-auto mb-0" }
const _hoisted_16 = { class: "text-muted extra-small ms-2" }
const _hoisted_17 = { class: "d-flex align-items-center" }
const _hoisted_18 = { class: "line-clamp me-auto" }
const _hoisted_19 = {
  key: 0,
  class: "badge badge-circle bg-primary ms-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "d-flex flex-column h-100 position-relative",
    key: $setup.lastRefresh
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode(" Title "),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", _hoisted_4, _toDisplayString($setup.props.title), 1 /* TEXT */)
        ]),
        _createCommentVNode(" Search "),
        _createElementVNode("div", _hoisted_5, [
          _createVNode($setup["Search"])
        ]),
        _createCommentVNode(" Chats "),
        _createElementVNode("div", _hoisted_6, [
          _createCommentVNode(" Card "),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.roomsList, (roomListItem) => {
            return (_openBlock(), _createElementBlock("a", {
              class: "card border-0 text-reset",
              onClick: ($event: any) => ($setup.router.push({ name: 'room', params: {id: roomListItem.id }})),
              style: {"cursor":"pointer"}
            }, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("img", {
                        src: '/api/v1/media/show/' + roomListItem.avatarId,
                        alt: "#",
                        class: "avatar-img"
                      }, null, 8 /* PROPS */, _hoisted_12)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("h5", _hoisted_15, _toDisplayString(roomListItem.name), 1 /* TEXT */),
                      _createElementVNode("span", _hoisted_16, _toDisplayString(roomListItem.lastMessageDate), 1 /* TEXT */)
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, _toDisplayString(roomListItem.lastMessageText), 1 /* TEXT */),
                      (roomListItem.unreadCount > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                            _createElementVNode("span", null, _toDisplayString(roomListItem.unreadCount), 1 /* TEXT */)
                          ]))
                        : _createCommentVNode("v-if", true)
                    ])
                  ])
                ])
              ]),
              _createCommentVNode(" .card-body ")
            ], 8 /* PROPS */, _hoisted_7))
          }), 256 /* UNKEYED_FRAGMENT */)),
          _createCommentVNode(" Card ")
        ]),
        _createCommentVNode(" Chats ")
      ])
    ])
  ]))
}