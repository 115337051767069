import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "layout overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createCommentVNode(" Application "),
    ($setup.authStore.isAuthenticated)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createCommentVNode(" Layout "),
          _createElementVNode("div", _hoisted_2, [
            _createCommentVNode(" Navigation "),
            _createVNode($setup["Navigation"]),
            _createCommentVNode(" Navigation "),
            _createCommentVNode(" Sidebar "),
            _createVNode($setup["Sidebar"]),
            _createCommentVNode(" Sidebar "),
            _createCommentVNode(" Content "),
            (_openBlock(), _createBlock(_component_router_view, {
              key: $setup.route.fullPath
            })),
            _createCommentVNode(" Content ")
          ]),
          _createCommentVNode(" Layout "),
          _createCommentVNode(" Modal: Invite "),
          _createVNode($setup["Invite"]),
          _createCommentVNode(" Modal: Profile "),
          _createVNode($setup["Profile"]),
          _createCommentVNode(" Modal: User profile "),
          _createVNode($setup["UserProfile"]),
          _createCommentVNode(" Modal: Media Preview "),
          _createVNode($setup["MediaPreview"])
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createCommentVNode(" /Application "),
          _createCommentVNode(" Login page "),
          _createElementVNode("div", null, [
            _createVNode($setup["SignIn"])
          ])
        ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */)),
    _createCommentVNode(" /Login page ")
  ]))
}