<template>
  <div class="modal fade" id="modal-user-profile" tabindex="-1" aria-labelledby="modal-user-profile" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-fullscreen-xl-down">
      <div class="modal-content">

        <!-- Modal body -->
        <div class="modal-body py-0">
          <!-- Header -->
          <div class="profile modal-gx-n">
            <div class="profile-img text-primary rounded-top-xl">
              <div v-html="(require(`!html-loader!/public/assets/img/core/card-header.svg`)).default"></div>

              <div class="position-absolute top-0 start-0 p-5">
                <button type="button" class="btn-close btn-close-white btn-close-arrow opacity-100" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            </div>

            <div class="profile-body">
              <div class="avatar avatar-xl">
                <img class="avatar-img" src="/assets/img/avatars/9.jpg" alt="#">

                <a href="#" class="badge badge-lg badge-circle bg-primary text-white border-outline position-absolute bottom-0 end-0" v-html="(require(`!html-loader!/public/assets/img/icons/plus.svg`)).default">
                </a>
              </div>

              <h4 class="mb-1">William Wright</h4>
              <p>last seen 5 minutes ago</p>
            </div>
          </div>
          <!-- Header -->

          <hr class="hr-bold modal-gx-n my-0">

          <!-- List -->
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="row align-items-center gx-6">
                <div class="col">
                  <h5>Location</h5>
                  <p>USA, Houston</p>
                </div>

                <div class="col-auto">
                  <div class="btn btn-sm btn-icon btn-dark" v-html="(require(`!html-loader!/public/assets/img/icons/globe.svg`)).default">
                  </div>
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="row align-items-center gx-6">
                <div class="col">
                  <h5>E-mail</h5>
                  <p>william@studio.com</p>
                </div>

                <div class="col-auto">
                  <div class="btn btn-sm btn-icon btn-dark" v-html="(require(`!html-loader!/public/assets/img/icons/mail.svg`)).default">
                  </div>
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="row align-items-center gx-6">
                <div class="col">
                  <h5>Phone</h5>
                  <p>1-800-275-2273</p>
                </div>

                <div class="col-auto">
                  <div class="btn btn-sm btn-icon btn-dark" v-html="(require(`!html-loader!/public/assets/img/icons/phone-call.svg`)).default">
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <!-- List -->

          <hr class="hr-bold modal-gx-n my-0">

          <!-- List -->
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="row align-items-center gx-6">
                <div class="col">
                  <h5>Notifications</h5>
                  <p>Enable sound notifications</p>
                </div>

                <div class="col-auto">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="user-notification-check">
                    <label class="form-check-label" for="user-notification-check"></label>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <!-- List -->

          <hr class="hr-bold modal-gx-n my-0">

          <!-- List -->
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <a href="#" class="text-reset">Send Message</a>
            </li>

            <li class="list-group-item">
              <a href="#" class="text-danger">Block User</a>
            </li>
          </ul>
          <!-- List -->
        </div>
        <!-- Modal body -->

      </div>
    </div>
  </div>
</template>
