import DIContainer, { IDIContainer, object, use} from "rsdi";
import {BootstrapService} from "./services/bootstrap.service";
import {RouterService} from "./services/router.service";
import {StateManagerService} from "./services/state-manager.service";
import {ApiClientService} from "./services/api-client.service";
import {FrontendContainerConfig} from "../../shared/types/common.types";
import {EventBusService} from "./services/event-bus.service";

export default function configureDI(config: FrontendContainerConfig): IDIContainer {
    const container:DIContainer = new DIContainer();


    // Parameters
    // container.add({});

    // Services
    container.add({
        [BootstrapService.name]: object(BootstrapService),
        [RouterService.name]: object(RouterService).construct(
            use(BootstrapService.name)
        ),
        [StateManagerService.name]: object(StateManagerService),
        [ApiClientService.name]: object(ApiClientService).construct(),
        [EventBusService.name]: object(EventBusService)
    })
    return container;
}
