import axios, { Axios } from "axios";
import {AxiosResponse} from "axios/index";
import {
    AuthServerUser,
    BackendAuthSuccessResponse, CreateRoomResponse, RoomListItem, UserEntityPublic,
} from "../../../shared/types/common.types";
import { UserEntity } from "../../../shared/entities/user.entity";
import { RosterItemEntity } from "../../../shared/entities/roster-item.entity";
import { PublishedFile } from "messenger-backend/src/types/storage";
import { RoomEntity } from "../../../shared/entities/roomEntity";
import { RoomMessageResponse } from "../../../shared/dto/room-messages-response.dto";

/**
 * Init API CLIENT (Axios)
 */
export class ApiClientService {
    private readonly axios: Axios;

    public constructor() {
        // Axios
        const instance = axios.create();
        instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        this.axios = instance;
    }

    public getAxios(): Axios {
        return this.axios;
    }

    /**
     * Авторизуемся на backend
     * @param username
     * @param password
     * @param code
     * @throws Error
     */
    public async auth(username: string, password: string, code: string): Promise<string> {
        let url: string = '/api/v1/user/auth'
        let data = new FormData();
        data.append('username', username);
        data.append('password', password);
        data.append('code', code);
        let response: AxiosResponse;
        try {
            response = await this.getAxios().post(url, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-Requested-With": "XMLHttpRequest"
                },
            });
        } catch (e) {
            if (e.response.data && e.response.data.message) {
                throw(new Error(e.response.data.message));
            } else {
                throw(e);
            }
        }
        let backendAuthSuccessResponse:BackendAuthSuccessResponse = response.data;
        let sessionToken = backendAuthSuccessResponse.sessionToken;

        return sessionToken;
    }

    /**
     * Получение JSON массива профиля текущего юзера.
     */
    public async getCurrentUserProfile(): Promise<UserEntity> {
        let response: AxiosResponse;
        response = await this.getAxios().get('/api/v1/user/profile');
        let user: UserEntity = response.data;
        return user;
    }

    /**
     * Запрос к backend по импорту юзеров из AuthServer.
     * @throws Error
     */
    public async syncUsers(): Promise<AuthServerUser[]> {
        let response: AxiosResponse;
        response = await this.getAxios().get('/api/v1/user/import');
        let users: AuthServerUser[] = response.data;
        return users;
    }

    /**
     * Get all users list
     */
    public async getUsers(): Promise<UserEntity[]> {
        let response: AxiosResponse;
        response = await this.getAxios().get('/api/v1/user/list');
        let users: UserEntity[] = response.data;
        return users;
    }

    /**
     * Get single user for admin
     * @param id
     */
    public async getUser(id: string): Promise<UserEntity> {
        let response: AxiosResponse;
        response = await this.getAxios().get('/api/v1/user/' + id);
        let user: UserEntity = response.data;
        return user;
    }

    /**
     * Возвращает публичную информацию о пользователе.
     * @param id
     */
    public async getUserPublic(id: string): Promise<UserEntityPublic> {
        let response: AxiosResponse;
        response = await this.getAxios().get('/api/v1/user/' + id + '/public');
        let user: UserEntityPublic = response.data;
        return user;
    }

    /**
     * Get single room
     * @param id
     */
    public async getRoom(id: string): Promise<RoomEntity> {
        let response: AxiosResponse;
        response = await this.getAxios().get('/api/v1/room/' + id);
        let room: RoomEntity = response.data;
        return room;
    }

    /**
     * Возвращает список сообщений группы
     */
    public async getRoomMessages(roomId: string, limit: number = 10, messageId: string = null): Promise<RoomMessageResponse> {
        let response: AxiosResponse;
        let requestOptions = {
            params: {
                limit,
                messageId
            },
        }
        response = await this.getAxios().get('/api/v1/room/'+ roomId + '/messages', requestOptions);
        let messages: RoomMessageResponse = response.data;
        return messages;
    }

    /**
     * Получение списка контактов для юзера.
     * @param userId
     */
    public async getContacts(ownerId: string): Promise<RosterItemEntity[]> {
        let response: AxiosResponse;
        response = await this.getAxios().get('/api/v1/roster/'+ownerId);
        let contacts: RosterItemEntity[] = response.data;
        return contacts;
    }

    /**
     * Получение списка комнат для пользователя.
     */
    public async getCurrentUserRoomsList(): Promise<{count: number, list: RoomListItem[]}> {
        let response: AxiosResponse;
        response = await this.getAxios().get('/api/v1/user/rooms_list');
        let list: {count: number, list: RoomListItem[]} = response.data;
        return list;
    }

    /**
     * Удаление контакта из контакт-листа
     * @param contactId
     */
    public async deleteContact(contactId: string): Promise<any> {
        let response: AxiosResponse;
        try {
            response = await this.getAxios().delete('/api/v1/roster/'+contactId, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-Requested-With": "XMLHttpRequest"
                },
            });
        } catch (e) {
            if (e.response.data && e.response.data.message) {
                throw(new Error(e.response.data.message));
            } else {
                throw(e);
            }
        }
    }

    public async addContacts(ownerId: string, userIds: string[]): Promise<void> {
        let url: string = '/api/v1/roster/'+ownerId+'/add'
        let data = new FormData();
        for (let userId of userIds) {
            data.append('userId[]', userId);
        }
        let response: AxiosResponse;
        try {
            response = await this.getAxios().post(url, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-Requested-With": "XMLHttpRequest"
                },
            });
        } catch (e) {
            if (e.response.data && e.response.data.message) {
                throw(new Error(e.response.data.message));
            } else {
                throw(e);
            }
        }
        let responseData:any = response.data;
    }

    /**
     * Авторизуемся на backend
     * @param username
     * @param password
     * @param code
     * @throws Error
     */
    public async updateAvatar(file: File): Promise<PublishedFile> {
        let url: string = '/api/v1/user/avatar/update'
        let data = new FormData();
        data.append('file', file);
        let response: AxiosResponse;
        try {
            response = await this.getAxios().post(url, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-Requested-With": "XMLHttpRequest"
                },
            });
        } catch (e) {
            if (e.response.data && e.response.data.message) {
                throw(new Error(e.response.data.message));
            } else {
                throw(e);
            }
        }
        let responseData: {
            status: string,
            publishedFile: PublishedFile,
        } = response.data;
        return responseData.publishedFile;
    }

    /**
     * Создание группы на backend.
     * @return ID вновь созданной группы
     * @throws Error
     */
    public async createRoom(
        avatar: File,
        name: string,
        description: string,
        isPublic: boolean,
        members: string[]
    ): Promise<string> {
        let url: string = '/api/v1/room/create'
        let data = new FormData();
        data.append('avatar', avatar);
        data.append('name', name);
        data.append('description', description);
        data.append('isPublic', isPublic.toString());
        for (let memberId of members) {
            data.append('members[]', memberId);
        }
        let response: AxiosResponse;
        try {
            response = await this.getAxios().post(url, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-Requested-With": "XMLHttpRequest"
                },
            });
        } catch (e) {
            if (e.response.data && e.response.data.message) {
                throw(new Error(e.response.data.message));
            } else {
                throw(e);
            }
        }
        let backendResponse:CreateRoomResponse = response.data;
        let id = backendResponse.id;

        return id;
    }
}
