// Load Styles
// Обычный Twitter Bootsrap из [Boilerplate](https://github.com/noreading/bootstrap5-webpack-boilerplate.git)
// import '../scss/main.scss';

// CSS с темой вставляется в <head> в шаблоне
// Светлая тема
// import '../scss/template.scss';
// ИЛИ черная тема
// import '../scss/template.dark.scss';

import * as bootstrap from "bootstrap"
import IsMobile from '../../../shared/is-mobile'
import { EmojiButton } from 'emoji-button'
import autosize from 'autosize'
import { Tab, Tooltip } from 'bootstrap'

/**
 * Init bootstrap
 */
export class BootstrapService {
    public initTemplateJavascript() {
        // this.initToggleOffcanvas();
        //this.initEmojiButton();
        this.initAutosize();
        this.initTabs();
        this.initTooltip();
        //this.initDropzone();
        this.initModal();
        this.initScrollable();
        this.initHorizontalScroll();
    }

    /**
     * Инийиализация Twitter Bootstrap фреймворка.
     * @param config
     * @private
     */
    public initTwitterBootstrap(config: any) {
        // Enabling tooltips
        if (config.tooltip) {
            const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))

            tooltipTriggerList.map(function (tooltipTriggerEl: any) {
                return new bootstrap.Tooltip(tooltipTriggerEl)
            })
        }

        // Enabling popovers
        if (config.popover) {
            const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))

            popoverTriggerList.map(function (popoverTriggerEl: any) {
                return new bootstrap.Popover(popoverTriggerEl, {})
            })
        }

        // Enabling toasts
        if (config.toasts) {
            const toastTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="toast"]'))

            toastTriggerList.map(function (toastTriggerEl: any) {
                // Define the target property
                let toastTarget = null

                if ("A" === toastTriggerEl.nodeName) {
                    toastTarget = toastTriggerEl.href || null

                    if (toastTarget.includes('#')) {
                        toastTarget = `#${toastTarget.split("#").pop()}`
                    } else {
                        return
                    }
                } else if ("BUTTON" === toastTriggerEl.nodeName) {
                    toastTarget = toastTriggerEl.dataset.bsTarget || null
                }

                // Check if the target exists
                const toastTargetEl = document.querySelector(toastTarget);

                if (!toastTargetEl) {
                    return
                }

                // Initialize toast
                const toast = new bootstrap.Toast(toastTargetEl, {})

                // Add click even to trigger
                toastTriggerEl.addEventListener("click", function (event: any) {
                    event.preventDefault();
                    toast.show()
                })
            })
        }
    }

    /**
     * Dark or light mode
     * import './template/themeswitcher';
     * @private
     */
    public initThemeSwitcher(): string {
        const LTCSS   = document.querySelectorAll('link[class=css-lt]');
        const DKCSS   = document.querySelectorAll('link[class=css-dk]');
        const MODEBTN = document.querySelectorAll('.switcher-btn');
        const MODEICO = document.querySelectorAll('.switcher-icon');

        if (localStorage.getItem('color-scheme')) {
            let scheme = localStorage.getItem('color-scheme');

            // @ts-ignore
            [...LTCSS].forEach((link) => {
                link.media = (scheme === 'light') ? 'all' : 'not all';
            });

            // @ts-ignore
            [...DKCSS].forEach((link) => {
                link.media = (scheme === 'dark') ? 'all' : 'not all';
            });
        }

        let getSystemScheme = function() {
            return matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        }


        let  getSavedScheme = function() {
            return localStorage.getItem('color-scheme');
        }

        let getCurrentScheme = function() {
            return getSavedScheme() ? getSavedScheme() : getSystemScheme();
        }

        let saveScheme = function(scheme: any) {
            localStorage.setItem('color-scheme', scheme)
        }

        let setScheme = function(scheme: any) {
            // @ts-ignore
            [...LTCSS].forEach((link) => {
                link.media = (scheme === 'light') ? 'all' : 'not all';
            });
            // @ts-ignore
            [...DKCSS].forEach((link) => {
                link.media = (scheme === 'dark') ? 'all' : 'not all';
            });
        }

        let setupSwitcher = function(scheme: any) {
            // @ts-ignore
            [...MODEICO].forEach((icon) => {
                let attribute = icon.getAttribute('data-theme-mode');

                if (attribute === scheme) {
                    icon.classList.toggle('d-none')
                }
            });
        }

        let scheme: string = getCurrentScheme();
        // @ts-ignore
        [...MODEBTN].forEach((btn) => {
            btn.addEventListener('click', (event: any) => {
                // @ts-ignore
                [...MODEICO].forEach((icon) => {
                    if (icon.classList.contains('d-none')) {
                        scheme = icon.getAttribute('data-theme-mode');
                    }
                    icon.classList.toggle('d-none')
                });
                if (scheme) {
                    saveScheme(scheme);
                    setScheme(scheme);
                }
            });
        });

        setupSwitcher(scheme);
        return scheme;
    }

    /**
     * Toggle chat
     * import './template/toggle-offcanvas';
     * @private
     */
    // public initToggleOffcanvas() {
    //     (function toggleChat() {
    //         const main = document.querySelector('.main');
    //         const elements = document.querySelectorAll('[data-toggle-chat]');
//
    //         Array.prototype.forEach.call(elements, (element: any) => {
    //             element.addEventListener('click', () => {
    //                 main.classList.toggle('is-visible');
    //             }, false);
    //         });
    //     })();
    // }

    /**
     * Emoji Button
     * import './template/emoji-button';
     * @private
     */
    public initEmojiButton() {
        const emojiForm = document.querySelectorAll('[data-emoji-form]');

        if ( !IsMobile.any() ) {
            emojiForm.forEach(form => {
                const button = form.querySelector('[data-emoji-btn]');
                const picker = new EmojiButton({
                    autoHide:           false,
                    emojiSize:          '1.125rem',
                    position:           'top',
                    zIndex:              1041,
                    recentsCount:        15,
                    showSearch:          true,
                    showPreview:         false,
                    showCategoryButtons: false,
                    emojisPerRow:        10,
                    rows:                8,
                    inputClass:         'form-control',
                    initialCategory:    (localStorage.getItem('emojiPicker.recent') === null) ? 'smileys' : 'recents',
                    i18n: {
                        search: 'Search',
                        categories: {
                            recents: 'Frequently Used',
                            smileys: 'Faces & Emotion',
                            people: 'People',
                            animals: 'Animals',
                            food: 'Food',
                            activities: 'Activities',
                            travel: 'Travel',
                            objects: 'Objects',
                            symbols: 'Symbols',
                            flags: 'Flags',
                            custom: 'Custom'
                        },
                        notFound: 'Oops! Nothing found!'
                    }
                } as EmojiButton.Options & {inputClass: string});
                //@ts-ignore
                picker.on('emoji', selection => {
                    //@ts-ignore
                    form.querySelector('[data-emoji-input]').value += selection.emoji;
                });

                button.addEventListener('click',  () => {
                    // @ts-ignore
                    picker.pickerVisible ? picker.hidePicker() : picker.showPicker(button);
                });
            });

        } else {
            emojiForm.forEach(form => {
                // @ts-ignore
                form.querySelector('[data-emoji-btn]').style.display = 'none';
            });
        }
    }

    /**
     * Autosize
     * import './template/autosize';
     * @private
     */
    public initAutosize() {
        autosize(document.querySelectorAll('[data-autosize="true"]'));
    }

    /**
     * Tabs
     * import './template/tabs';
     * @private
     */
    private initTabs() {
        const tabsTriggerList = document.querySelectorAll('[data-theme-toggle="tab"]');

        tabsTriggerList.forEach(tabTriggerEl => {
            tabTriggerEl.addEventListener('click', e => {
                e.preventDefault();
                // @ts-ignore
                new Tab(document.querySelector(tabTriggerEl.hash)).show();
            });
        });
    }

    /**
     * Bootstrap Tooltips
     * import './template/tooltip';
     * @private
     */
    public initTooltip() {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');

        tooltipTriggerList.forEach(tooltip => {
            new Tooltip(tooltip, {
                html: true
            });
        });
    }

    public initDropzone() {
        const dzTemplate = `
<div class="theme-file-preview position-relative mx-2">
    <div class="avatar avatar-lg dropzone-file-preview">
        <span class="avatar-text rounded bg-secondary text-body file-title">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
        </span>
    </div>

    <div class="avatar avatar-lg dropzone-image-preview">
        <img src="#" class="avatar-img rounded file-title" data-dz-thumbnail="" alt="" >
    </div>

    <a class="badge badge-circle bg-body text-white position-absolute top-0 end-0 m-2" href="#" data-dz-remove="">
        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
    </a>
</div>
`;
        if (document.querySelector('.chat-body-inner') && document.querySelector('.chat-body-inner')) {
            const watchHeight = document.querySelector('.chat-footer');

            let outputSize = function() {
                const target = document.querySelector('.chat-body-inner');

                if (target && watchHeight) {
                    // @ts-ignore
                    target.setAttribute('style', `padding-bottom: ${Number(watchHeight.offsetHeight)}px`);
                }
            }

            new ResizeObserver(outputSize).observe(watchHeight);
        }

        if (document.querySelector('[data-dropzone-area]')) {
            const { Dropzone } = require("dropzone");
            const dropzone = new Dropzone('[data-dropzone-area]', {
                url:               'post.php',
                clickable:         '#dz-btn',
                previewsContainer: '#dz-preview-row',
                previewTemplate:   dzTemplate
            });
            dropzone.on('addedfile', function(file: any) {
                let preview = document.querySelectorAll('.theme-file-preview')
                preview = preview[preview.length -1].querySelectorAll('.file-title');

                for (let i = 0; i < preview.length; i++) {
                    // @ts-ignore
                    preview[i].title = file.name;
                }
            });

            dropzone.on('addedfiles', function(file: any) {
                dropzone.previewsContainer.classList.add('dz-preview-moved', 'pb-10', 'pt-3', 'px-2');
            });

            dropzone.on('reset', function(file: any) {
                dropzone.previewsContainer.classList.remove('dz-preview-moved', 'pb-10', 'pt-3', 'px-2');
            });
        }
    }

    /**
     * Passing img src to Bootstrap modal
     * import './template/modal';
     * @private
     */
    private initModal() {
        const modalPreview = document.getElementById('modal-media-preview');

        if (modalPreview) {
            modalPreview.addEventListener('show.bs.modal', function (event) {
                // @ts-ignore
                let button = event.relatedTarget;
                let recipient = button.getAttribute('data-theme-img-url');
                let modalImg = modalPreview.querySelector('.modal-preview-url')
                // @ts-ignore
                modalImg.src = recipient;
            })
        }
    }

    /**
     * Scroll to end of the chat
     * import './template/scrollable';
     * @private
     */
    private initScrollable() {
        let scrollable = function(selector: any) {
            selector = document.querySelectorAll(selector);

            if (selector.length) {
                selector[selector.length - 1].scrollIntoView(false, {
                    block: 'end'
                });
            }
        };

        window.onload = function() {
            scrollable('.chat-body-inner');
        };
    }

    /**
     * Horizontal scroll
     * import './template/horizontal-scroll';
     * @private
     */
    private initHorizontalScroll() {
        [].forEach.call(document.querySelectorAll('[data-horizontal-scroll]'), function (el: any) {
            function scrollHorizontally(e: any) {
                e = window.event || e;
                const delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
                el.scrollLeft -= (delta*28);
                e.preventDefault();
            }

            if (el.addEventListener) {
                el.addEventListener('mousewheel', scrollHorizontally, false);
                el.addEventListener('DOMMouseScroll', scrollHorizontally, false);
            } else {
                el.attachEvent('onmousewheel', scrollHorizontally);
            }
        });
    }
}
