<template>
  <div>Content from NOT FOUND component</div>
</template>

<script setup lang="ts">

</script>

<style scoped>
</style>
