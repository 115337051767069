import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card border-0" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "row align-items-center gx-5" }
const _hoisted_4 = { class: "col-auto" }
const _hoisted_5 = {
  href: "#",
  class: "avatar {{ props.online }}"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "avatar-text"
}
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "col-auto" }
const _hoisted_10 = {
  key: 0,
  class: "dropdown"
}
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "dropdown-menu" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" Card "),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("a", _hoisted_5, [
              ($setup.props.avatar)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "avatar-img",
                    src: $setup.props.avatar,
                    alt: ""
                  }, null, 8 /* PROPS */, _hoisted_6))
                : _createCommentVNode("v-if", true),
              ($setup.props.abbr)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($setup.props.abbr), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("h5", null, [
              _createElementVNode("a", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('friendNameClick'))),
                style: {"cursor":"pointer"}
              }, _toDisplayString($props.item.contact.name), 1 /* TEXT */)
            ]),
            _createElementVNode("p", null, _toDisplayString($setup.props.status), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createCommentVNode(" Dropdown "),
            ($setup.props.actions && $setup.props.actions.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("a", {
                    class: "icon text-muted",
                    href: "#",
                    role: "button",
                    "data-bs-toggle": "dropdown",
                    "aria-expanded": "false",
                    innerHTML: (require(`!html-loader!/public/assets/img/icons/more-vertical.svg`)).default
                  }, null, 8 /* PROPS */, _hoisted_11),
                  _createElementVNode("ul", _hoisted_12, [
                    ($setup.props.actions.includes($setup.RosterItemAction.createMessage))
                      ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                          _createCommentVNode("                <a class=\"dropdown-item\" href=\"#\">New message</a>"),
                          _createVNode(_component_router_link, {
                            class: "dropdown-item",
                            to: { name: 'room', params: { id: $setup.props.item.roomId }}
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("New message")
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["to"])
                        ]))
                      : _createCommentVNode("v-if", true),
                    _createCommentVNode(" <li><hr class=\"dropdown-divider\"></li> "),
                    ($setup.props.actions.includes($setup.RosterItemAction.remove))
                      ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                          _createElementVNode("a", {
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit($setup.eventRosterItemRemove, {id: $setup.props.item.id}))),
                            class: "dropdown-item text-danger",
                            style: {"cursor":"pointer"}
                          }, "Remove from roster")
                        ]))
                      : _createCommentVNode("v-if", true)
                  ])
                ]))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ])
    ]),
    _createCommentVNode(" Card ")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}