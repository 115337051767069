import { JwtPayload } from "jsonwebtoken";
import { Message } from "vue-advanced-chat";

export type AuthServerJwtPayload = JwtPayload & {
    name?: string,
    nickname?: string,
    roles?: string[],
    id?: number
}

/**
 * Auth server response, where
 * object: jwt as object for store in frontend
 * string: jwt as string for COOKIE_ID
 */
export type BackendAuthSuccessResponse = {
    sessionToken: string
}

export type BackendErrorResponse = {
    status: string,
    message: string
}

export type JsonWebToken = {
    aud: string,
    exp: number,
    iat: number,
    id: number,
    iss: string,
    name: string,
    nickname: string,
    roles: string[],
    sub: string
}

export type InternalError = {
    code?: number,
    message: string
}

export type FrontendContainerConfig = {
    serverPath: string
}

export const COOKIE_SESSION_ID = 'SESSID';

// Формат пользователя импорт импортируемого с AUTH_SERVER
export type AuthServerUser = {
    id: number,
    name: string,
    type: ('internal'|'external'),
    mobileAppAllowServerChange: boolean,
    username: string,
    firstName: string,
    lastName: string,
    enabled: boolean,
    roles: string[],
    email: string
}

export enum RosterItemAction {
    createMessage = 1,
    remove,
}

export type CreateRoomResponse = {
    status: string;
    id: string; // Saved room id
}

export const
    EVENT_CONNECT: string = 'connect',
    EVENT_DISCONNECT: string = 'disconnect',
    EVENT_OPEN_ROOM: string = 'open-room',
    EVENT_SEND_MESSAGE: string = 'send-message',
    EVENT_REACTION: string = 'reaction',
    EVENT_DELETE_MESSAGE: string = 'delete-message',
    EVENT_TYPING_MESSAGE: string = 'typing-message',
    EVENT_EDIT_MESSAGE: string = 'edit-message';

export type ConnectionStatus = 'online' | 'offline';

export type RoomOpenEvent = {
    roomId: string,
};

export type MessageInRoom = Message & {
    roomId: string;
}

/**
 * Публичная информация о пользователе по его ID
 */
export type UserEntityPublic = {
    id: string;
    name: string;
    username: string;
    avatarId: string;
}

/**
 * Элемент списка
 */
export type RoomListItem = {
    id: string;
    avatarId: string;
    name: string;
    lastMessageText: string|null;
    lastMessageDate: Date|null;
    unreadCount: number;
}
