import { createPinia, Pinia } from 'pinia'
import piniaPersist from 'pinia-plugin-persist'

/**
 * Init pinia
 */
export class StateManagerService {
    private readonly pinia: Pinia;

    public constructor() {
        let pinia = createPinia();
        pinia.use(piniaPersist);
        this.pinia = pinia;
    }

    public getPinia(): Pinia {
        return this.pinia;
    }
}
